<template>
<v-container id="harvestersComponent">
    <FirstChartSetComponent />
</v-container>
</template>

<script>
import FirstChartSetComponent from '@/components/dashboard/mock-chartset/FirstChartSetComponent.vue'

export default {
  name: 'TrailersView',
  components: {
    FirstChartSetComponent
  },
  props: {
  },
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
.container, .v-tabs-items, .v-tabs, .v-item-group{
  height: 100% !important;
}
#harvestersComponent {
  height: 100%;
  width: 95%;
  margin: auto
}
</style>
